import React, { useRef } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "components/seo"
import BlogGrid from "components/blogGrid"
import Slider from 'components/slider'

import { times } from 'lodash'

export default ({ data, location }) => {
    const {
        page: {
            title,
            content
        },
        development: {
            developmentFields: {
                mapImage,
                contactDepartment,
                images,
                additionalCopy,
                details,
                energyRating,
                completionDate,
                contactName,
                contactLogo,
                contactAddress,
                contactLink
            }
        }
    } = data.siteFields

    let excerpt = data.content ? data.content.replace(/(<([^>]+)>)/ig, "") : ''
    excerpt = excerpt ? excerpt.slice(0, 160) : ''
    if (excerpt.length === 160) {
        excerpt = excerpt + '...'
    }

    const sliderRef = useRef(null)

    const flickityOptions = {
        wrapAround: true,
        prevNextButtons: true,
        pageDots: false,
        cellAlign: 'center',
    }

    return (
        <Layout location={ location }>

            <SEO
                title={title}
                description={excerpt}
            />

            <div className="development">

                <div className="container">
                    <div className="development-content">
                        <h1 dangerouslySetInnerHTML={{ __html: title }} />
                        <div dangerouslySetInnerHTML={{ __html: content }} className="content" />
                        {images &&
                            <Slider
                                className={'development-slideshow'}
                                options={flickityOptions}
                                reloadOnUpdate
                                static
                                flickityRef={ref => sliderRef.current = ref}
                            >
                                {images.map((row, index) => {
                                    const {
                                        image
                                    } = row

                                    return (
                                        <div className="development-slideshow-item" key={index}>
                                            <img src={image.mediaItemUrl} alt={title} />
                                        </div>
                                    )

                                })}
                            </Slider>
                        }

                        <div dangerouslySetInnerHTML={{ __html: additionalCopy }} className="content development-additionalCopy" />
                    </div>
                    <div className="development-details-cont">
                        <div className="development-details">
                            {mapImage &&
                                <div className="development-details-map-cont">
                                    <div className="development-details-map" style={{ backgroundImage: `url(${mapImage.mediaItemUrl})` }} />
                                    <svg width="0" height="0">
                                        <defs>
                                            <clipPath id="clipPath-circle" clipPathUnits="objectBoundingBox" transform="scale(0.002, 0.002)">
                                                <circle cx="250" cy="250" r="250" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            }

                            {details &&
                                <div className="development-details-text">
                                    <h3 dangerouslySetInnerHTML={{ __html: 'Details' }} />
                                    <div dangerouslySetInnerHTML={{ __html: details }} />
                                </div>
                            }
                            {energyRating &&
                                <div className="development-details-text">
                                    <h3 dangerouslySetInnerHTML={{ __html: 'Energy Rating' }} />
                                    <div className={'stars stars-' + energyRating}>
                                        { times(10, i => (
                                            <div key={i} />
                                        ))}
                                        <div className="stars-full">
                                            { times(10, i => (
                                                <div key={i} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            }
                            {completionDate &&
                                <div className="development-details-text">
                                    <h3 dangerouslySetInnerHTML={{ __html: 'Completion Date' }} />
                                    <div dangerouslySetInnerHTML={{ __html: completionDate }} />
                                </div>
                            }
                        </div>
                        <div className="development-contact">
                            {contactLogo &&
                                <img src={contactLogo.mediaItemUrl} alt={contactName} />
                            }
                            {contactAddress &&
                                <div dangerouslySetInnerHTML={{ __html: contactAddress }} />
                            }
                            {contactLink &&
                                <a href={contactLink.url} dangerouslySetInnerHTML={{ __html: contactLink.title }} target="_blank" rel="noopener noreferrer" />
                            }
                            {contactDepartment && contactDepartment.map((row, index) => {
                                const {
                                    title: _title,
                                    name,
                                    phone,
                                    mobile,
                                    email
                                } = row

                                return (
                                    <div key={index} className="development-contact-department">
                                        <h4 dangerouslySetInnerHTML={{ __html: _title }} />
                                        <div dangerouslySetInnerHTML={{ __html: name }} />
                                        <div dangerouslySetInnerHTML={{ __html: `<span>T:</span> ${ phone }` }} />
                                        <div dangerouslySetInnerHTML={{ __html: `<span>M: </span> ${ mobile }` }} />
                                        <a href={'mailto:' + email} dangerouslySetInnerHTML={{ __html: 'Enquire now' }} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="BlogGrid scrolled-into-view">
                <BlogGrid 
                    title="<h1>What's On <em>at Pentridge</em></h1>" 
                    featureColour="yellow" 
                    type="whatsOn" 
                />
            </div>
        </Layout>
    )
}

export const query = graphql`
    query($databaseId: ID!) {
        siteFields {
            page(id: $databaseId, idType: DATABASE_ID) {
                databaseId
                title
                date
                content(format: RENDERED)
                featuredImage {
                    node {
                        altText
                        title(format: RENDERED)
                        mediaItemUrl
                        slug
                    }
                }
            }
            development(id: $databaseId, idType: DATABASE_ID) {
                title
                slug
                content(format: RENDERED)
                developmentFields {
                    status
                    details
                    energyRating
                    completionDate
                    mapImage {
                        mediaItemUrl
                    }
                    contactLogo {
                        mediaItemUrl
                    }
                    contactName
                    contactAddress
                    contactLink {
                        url
                        title
                    }
                    contactDepartment {
                        title
                        name
                        phone
                        mobile
                        email
                    }
                    images {
                        image {
                            mediaItemUrl
                        }
                    }
                    additionalCopy
                }
            }
        }
    }
`
